import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app-state/index';
import { viewProjectActions } from './view-project.actions';
import { viewProjectSelectors } from './view-project.selectors';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  repeat,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ProjectApiService } from '../../services/project-api.service';
import { NotificationsService } from '../../services/notifications.service';
import { forkJoin, of } from 'rxjs';
import { CurrentUserService } from '../../services/current-user.service';
import { ProjectSpendService } from '../../services/project-spend.service';
import { commitmentsActions } from '../commitments/commitments.actions';
import { itemTrackingActions } from '../item-tracking/item-tracking.actions';
import { loadSpends, setProjectStartDate, spendActionTypes } from '../spend/spend.actions';
import * as moment from 'moment/moment';
import {
  PROJECT_VIEWS,
  VIEW_PROJECT_REFRESH_CAUSE,
} from '../../framework/constants/view-project.constants';
import { USER_MANAGER } from '../../framework/constants/user.constants';
import { ProjectDescriptionsService } from '../../services/project-descriptions.service';
import { ProjectDescriptionPayload } from '../../pages/webapp/projects/view-project/project-updates/project-updates.types';
import { ReportingService } from '../../services/reporting.service';

@Injectable()
export class ViewProjectEffects {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private projectService: ProjectApiService,
    private notif: NotificationsService,
    private userService: CurrentUserService,
    private spendService: ProjectSpendService,
    private descriptionsService: ProjectDescriptionsService,
    private reportService: ReportingService,
  ) {}

  // loadProject$ = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(viewProjectActions.selectedProjectChanged),
  //     withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
  //     switchMap(([action, state]) => {
  //       this.store.dispatch(viewProjectActions.startedLoading());
  //       return this.projectService
  //         .getProjectWithObservable(action.projectId)
  //         .pipe(map((project) => viewProjectActions.projectLoaded({ project })));
  //     }),
  //     catchError((error) => {
  //       this.notif.showError(error?.message || 'An error occurred while loading the project');
  //       return of(viewProjectActions.cancel());
  //     }),
  //   ),
  // );

  viewChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.viewChanged),
      withLatestFrom(this.userService.data$),
      map(([action, user]) => {
        const isManager = user.type.name === USER_MANAGER;
        if (action.view === PROJECT_VIEWS.DEFAULT) {
          const view = isManager ? PROJECT_VIEWS.PROJECT_SPEND : PROJECT_VIEWS.COMMITMENTS;
          return viewProjectActions.changeView({ view });
        }
        this.reportService.setSelectedProjectView(action.view);
        return viewProjectActions.changeView({ view: action.view });
      }),
    ),
  );

  loadTemplateByProperty$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.loadTemplatesByProperty),
      exhaustMap((action) => {
        return this.projectService.getBudgetTemplates(action.propertyId).pipe(
          map((templates) => {
            return viewProjectActions.projectTemplatesLoaded({ templates });
          }),
        );
      }),
    ),
  );

  refresh$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.refreshNeeded, viewProjectActions.selectedProjectChanged),
      tap((action) => console.log('refresh effect', action, performance.now())),
      withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
      mergeMap(([action, state]) => {
        const projectId = (action as any).projectId ?? state.currentProjectId;
        this.store.dispatch(viewProjectActions.startedLoading({}));
        this.store.dispatch(commitmentsActions.projectChanged({ projectId }));

        if ('cause' in action && action.cause === VIEW_PROJECT_REFRESH_CAUSE.SPEND_SET_ALL) {
          // refresh only the line items, the other data is already loaded
          this.store.dispatch(viewProjectActions.loadLineItems({ projectId }));
          return of(viewProjectActions.cancel());
        }

        if (this.userService.isManager) {
          this.store.dispatch(loadSpends({ projectId }));
          this.store.dispatch(viewProjectActions.loadSpends({ projectId }));
          this.store.dispatch(viewProjectActions.loadLineItems({ projectId }));
          this.store.dispatch(itemTrackingActions.setActiveProject({ projectId }));
        }

        return this.projectService.getProjectWithObservable(state.currentProjectId).pipe(
          catchError((error) => {
            console.warn('Error loading project', error?.error?.message);
            this.notif.showError(
              error?.error?.message || 'An error occurred while loading the project',
            );
            return of(null);
          }),
          tap((project) => {
            if (!project) {
              return;
            }

            this.store.dispatch(
              viewProjectActions.loadTemplatesByProperty({ propertyId: project.property_id }),
            );

            this.store.dispatch(
              commitmentsActions.setCommitmentProjectData({
                projectData: {
                  id: project.id,
                  start_date: project.start_date,
                },
              }),
            );

            if (this.userService.isManager) {
              this.store.dispatch(
                setProjectStartDate({
                  projectStartDate: moment.utc(project.start_date).format('YYYY-MM-DD'),
                }),
              );
              this.store.dispatch(
                spendActionTypes.setHasProjectTemplateFromViewProject({
                  hasTemplates: !!project?.budget_template_id,
                }),
              );
            }
          }),
          map((project) => {
            if (!project) {
              return viewProjectActions.cancel();
            }
            this.notif.close();
            return viewProjectActions.projectLoaded({ project });
          }),
        );
      }),
    ),
  );

  loadSpends$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.loadSpends),
      withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
      switchMap(([action, state]) => {
        this.store.dispatch(viewProjectActions.startedLoading({ target: 'spends' }));
        const projectId = action.projectId ?? state.currentProjectId;
        return this.spendService.getAllWithObservable(projectId).pipe(
          catchError((error) => {
            this.notif.showError(
              error?.error?.message || 'An error occurred while fetching spend data.',
            );
            this.store.dispatch(viewProjectActions.loadingEnded({ target: 'spends' }));
            console.warn('get all spends for overview error', error);
            return of(undefined);
          }),
          map((spends) => {
            if (!spends) {
              return viewProjectActions.cancel();
            }
            return viewProjectActions.spendsLoaded({ projectId, spends });
          }),
        );
      }),
    ),
  );

  DescriptionsSaved$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(viewProjectActions.reloadProjectDescriptions, viewProjectActions.successfullySaved),
        tap((_) => this.notif.showSuccess('Successfully saved!')),
      ),
    { dispatch: false },
  );

  loadProjectDescriptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        viewProjectActions.loadProjectDescriptions,
        viewProjectActions.reloadProjectDescriptions,
      ),
      withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
      switchMap(([action, state]) => {
        const projectId = action.projectId ?? state.currentProjectId;
        return this.descriptionsService.getProjectDescriptions(projectId).pipe(
          map((descriptions) => {
            return viewProjectActions.projectDescriptionsLoaded({
              projectDescriptions: descriptions,
              projectId: state.currentProjectId,
            });
          }),
        );
      }),
      catchError((error) => {
        this.notif.showError(
          error?.error?.message || 'An error occurred while project loading updates.',
        );
        console.warn('project loading data err', error);
        return of(viewProjectActions.cancel());
      }),
      repeat(),
    ),
  );

  updateProjectDescriptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.updateProjectDescriptions),
      withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
      mergeMap(([action, state]) => {
        const payload: Partial<ProjectDescriptionPayload> = {
          ...action.projectDescription,
          project_id: state.currentProjectId,
        };
        return this.descriptionsService.updateProjectDescription(payload).pipe(
          map((_) => {
            // return viewProjectActions.reloadProjectDescriptions({
            //   projectId: state.currentProjectId,
            // });
            return viewProjectActions.successfullySaved();
          }),
        );
      }),
      catchError((error) => {
        this.notif.showError(
          error?.error?.message || 'An error occurred while updating project updates page.',
        );
        console.warn('project updates data.', error);
        return of(viewProjectActions.cancel());
      }),
      repeat(),
    ),
  );

  createProjectDescriptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.createProjectDescriptions),
      withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
      mergeMap(([action, state]) => {
        const payload: ProjectDescriptionPayload = {
          ...action.projectDescription,
          project_id: state.currentProjectId,
        };
        delete payload.id;
        return this.descriptionsService.createProjectDescription(payload).pipe(
          map((_) => {
            // return viewProjectActions.reloadProjectDescriptions({
            //   projectId: state.currentProjectId,
            // });
            return viewProjectActions.successfullySaved();
          }),
        );
      }),
      catchError((error) => {
        this.notif.showError(
          error?.error?.message || 'An error occurred while creating update page.',
        );
        console.warn('project updates data.', error);
        return of(viewProjectActions.cancel());
      }),
      repeat(),
    ),
  );

  deleteProjectDescription$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.deleteProjectDescriptions),
      withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
      exhaustMap(([action, state]) => {
        return this.descriptionsService
          .deleteProjectDescription(action.descriptionId, { project_id: state.currentProjectId })
          .pipe(
            map((_) => {
              return viewProjectActions.deleteProjectDescriptionFromStore({
                id: action.descriptionId,
              });
            }),
            catchError((error) => {
              this.notif.showError(error?.error?.message ?? 'Could not delete.');
              return of(viewProjectActions.cancel());
            }),
          );
      }),
      repeat(),
    ),
  );

  saveProjectDescriptionsAsync$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.saveProjectDescriptionsAsync),
      withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
      mergeMap(([action, state]) => {
        const updateObservables = action.updatedDescriptions.map((description) => {
          const payload: Partial<ProjectDescriptionPayload> = {
            ...description,
            project_id: state.currentProjectId,
          };
          return this.descriptionsService.updateProjectDescription(payload);
        });

        const createObservables = action.newDescriptions.map((description) => {
          const payload: ProjectDescriptionPayload = {
            ...description,
            project_id: state.currentProjectId,
          };
          delete payload.id;
          return this.descriptionsService.createProjectDescription(payload);
        });

        const deleteObservables = action.deletedIds.map((id) => {
          return this.descriptionsService.deleteProjectDescription(id, {
            project_id: state.currentProjectId,
          });
        });

        return forkJoin([...updateObservables, ...createObservables, ...deleteObservables]).pipe(
          map((_) => {
            return viewProjectActions.reloadProjectDescriptions({
              projectId: state.currentProjectId,
            });
          }),
          catchError((error) => {
            this.notif.showError(
              error?.error?.message || 'An error occurred while saving project descriptions.',
            );
            console.warn('project descriptions data error:', error);
            return of(viewProjectActions.cancel());
          }),
        );
      }),
      repeat(),
    ),
  );

  loadLineItems$ = createEffect(() =>
    this.actions.pipe(
      ofType(viewProjectActions.loadLineItems),
      withLatestFrom(this.store.select(viewProjectSelectors.getViewProjectState)),
      switchMap(([action, state]) => {
        this.store.dispatch(viewProjectActions.startedLoading({ target: 'lineItems' }));
        const projectId = action.projectId ?? state.currentProjectId;
        return this.spendService.getLineItemsByProjectId(projectId, true).pipe(
          catchError((error) => {
            this.notif.showError(
              error?.error?.message || 'An error occurred while fetching line items.',
            );
            this.store.dispatch(viewProjectActions.loadingEnded({ target: 'lineItems' }));
            console.warn('get all line items for overview error', error);
            return of(undefined);
          }),
          map((lineItems) => {
            if (!lineItems) {
              return viewProjectActions.cancel();
            }
            return viewProjectActions.lineItemsLoaded({ projectId, lineItems });
          }),
        );
      }),
    ),
  );
}
