import { createReducer, on } from '@ngrx/store';
import {
  Cashflow,
  CashflowFilters,
  getCashflowDataSorted,
  NameSorting,
} from '../../framework/constants/cashflow.constants';
import { cashflowActions } from './cashflow.actions';

export const cashflowFeatureKey = 'cashflow';

export interface CashflowState {
  isLoading: boolean;
  currentProjectId?: number;
  filters: CashflowFilters;
  cashflow: Cashflow;
  sortNameBy: NameSorting;
}

export const initialState: CashflowState = {
  isLoading: false,
  currentProjectId: null,
  filters: {
    view: 'projects',
    project_ids: [],
    property_ids: [],
    year: new Date().getFullYear(),
  },
  cashflow: null,
  sortNameBy: 'row_number',
};

export const cashflowReducer = createReducer(
  initialState,
  on(cashflowActions.filtersChanged, (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.filters,
      },
    };
  }),
  on(cashflowActions.startedLoading, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(cashflowActions.cashflowLoaded, (state, action) => {
    return {
      ...state,
      cashflow: action.cashflow,
      isLoading: false,
    };
  }),
  on(cashflowActions.toggleNameSorting, (state, action) => {
    let newNameSorting: NameSorting;
    if (state.sortNameBy === 'asc') {
      newNameSorting = 'desc';
    } else if (state.sortNameBy === 'desc') {
      newNameSorting = 'row_number';
    } else {
      newNameSorting = 'asc';
    }

    return {
      ...state,
      sortNameBy: newNameSorting,
      cashflow: {
        ...state.cashflow,
        data: getCashflowDataSorted(state.cashflow.data, newNameSorting),
      },
    };
  }),
);
