import { Injectable } from '@angular/core';
import {
  PROJECT_ATTRIBUTES_OBJ,
  PROJECT_STATUS_ID,
} from '../framework/constants/project.constants';
import { CAPEX_ID, OPEX_ID } from '../pages/webapp/projects/add-project/general/general.component';
import {
  defaultMonthlyData,
  specialCharReplaceRegexp,
} from '../framework/constants/spend.constants';
import { FiscalService } from './fiscal.service';
import { Cashflow, CashflowFilters } from '../framework/constants/cashflow.constants';
import { RestRequestService } from '../restApi/rest-request.service';
import { REST_CASHFLOW_BASE } from '../restApi/RestRoutes';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CashFlowService {
  lineItems;
  projects = [];
  allYearOptions = new Set<number>();
  projectYearOptions = [];
  projectAttributes = PROJECT_ATTRIBUTES_OBJ;

  constructor(
    private fiscalService: FiscalService,
    private rest: RestRequestService,
  ) {}

  getCashFlowData(filters: CashflowFilters): Observable<Cashflow> {
    const project_status_ids = [
      PROJECT_STATUS_ID.BIDDING,
      PROJECT_STATUS_ID.AWARDED,
      PROJECT_STATUS_ID.PLANNED,
      PROJECT_STATUS_ID.IN_PROGRESS,
      PROJECT_STATUS_ID.COMPLETED,
    ];
    const extendedFilters: CashflowFilters = { ...filters, project_status_ids };
    const params = this.convertArrayParams(extendedFilters);
    return this.rest.getWithObservable(REST_CASHFLOW_BASE, {}, params);
  }

  private convertArrayParams(filters: CashflowFilters) {
    const convertedFilters = { ...filters };
    if (filters?.project_ids) {
      convertedFilters['project_ids[]'] = filters.project_ids;
      delete convertedFilters.project_ids;
    }
    if (filters?.property_ids) {
      convertedFilters['property_ids[]'] = filters.property_ids;
      delete convertedFilters.property_ids;
    }
    if (filters?.project_status_ids) {
      convertedFilters['project_status_ids[]'] = filters.project_status_ids;
      delete convertedFilters.project_status_ids;
    }

    if (!filters?.searchText) {
      delete convertedFilters.searchText;
    }
    return convertedFilters;
  }
  getAllYearOptions() {
    return Array.from(this.allYearOptions).sort((a, b) => {
      return a < b ? -1 : 1;
    });
  }

  getFiscalYearFilterOptions$(): Observable<{ value: number; label: string }[]> {
    return this.fiscalService.fiscalYear$.pipe(
      filter((fiscalYear) => !!fiscalYear),
      map((currentFiscalYear) => {
        const fiscalYearOptions = [];
        for (let year = currentFiscalYear - 10; year <= currentFiscalYear + 10; year++) {
          fiscalYearOptions.push({ value: year, label: `FY ${year}` });
        }
        console.log('fiscalYearOptions', fiscalYearOptions, currentFiscalYear);
        return fiscalYearOptions;
      }),
    );
  }

  // todo types when it's refactored
  /**
   * sorts projects/line items by 'name' field ignoring special characters
   */
  sortFunction = (a: any, b: any) => {
    const aField = a.name.replace(specialCharReplaceRegexp, '');
    const bField = b.name.replace(specialCharReplaceRegexp, '');
    return aField.localeCompare(bField);
  };

  /**
   * @deprecated - use backend data instead
   */
  filterForProjects(fiscalYear: number, attributes: any[], status: any[]) {
    const showAllAttributes = attributes.includes('all');
    const isCapex = attributes.includes(this.projectAttributes.capex);
    const isOpex = attributes.includes(this.projectAttributes.opex);
    // filter only if one of capex or opex is given
    // do not filter when both is provided
    const doFilterSpendCategory = !((isCapex && isOpex) || (!isCapex && !isOpex));
    const spendCategoryId = isCapex ? CAPEX_ID : OPEX_ID;
    const filterPriority = attributes.includes(this.projectAttributes.priority);
    const filterApproved = attributes.includes(this.projectAttributes.approved);
    const filteredProjects = this.projects.filter((project) => {
      if (!showAllAttributes) {
        if (filterPriority && !project.priority) {
          return false;
        }
        if (filterApproved && !project.approved) {
          return false;
        }
        if (doFilterSpendCategory && project.spend_category !== spendCategoryId) {
          return false;
        }
      }
      if (status.length && !status.includes(project.status)) {
        return false;
      }
      // add the next line back if you want to filter out projects which don't have budget in selected FY
      // return project.actuals_or_forecast.filter((d) => d.year === fiscalYear).length > 0;
      return true;
    });

    return this.filterForViewProject(filteredProjects, fiscalYear);
  }

  /**
   * @deprecated - use backend data instead
   */
  filterForViewProject(lineItems, fiscalYear: any) {
    const filteredLineItems = [];
    if (!fiscalYear) {
      console.warn('No FY for cashflow service, this may break things.');
    }
    if (!lineItems) {
      console.warn('No line items for cashflow service, this may break things.');
      return;
    }

    lineItems.forEach((currentLine) => {
      const findYear = (item) => item.year === fiscalYear;
      const actualsOrForecastData = currentLine.actuals_or_forecast.find(findYear);
      const budget = currentLine.budget.find(findYear);
      const actuals = currentLine.actuals.find(findYear);
      const forecast = currentLine.forecast.find(findYear);
      // place all zero lines if project doesn't have data for the selected FY
      const defaultValue = { values: new Array(12).fill(0), year: fiscalYear };
      const lineItem = {
        ...currentLine,
        actuals_or_forecast: actualsOrForecastData ? actualsOrForecastData : { ...defaultValue },
        budget: budget ? budget : { ...defaultValue },
        forecast: forecast ? forecast : { ...defaultValue },
        actuals: actuals ? actuals : { ...defaultValue },
      };
      filteredLineItems.push(lineItem);
    });
    return filteredLineItems;
  }

  /**
   * @deprecated - use backend data instead
   */
  buildCashFlowData(projects) {
    const projectsData = [];
    projects.forEach((project) => {
      const projectData = {
        id: project.id,
        name: project.title,
        approved: project.approved,
        spend_category: project.spend_category,
        priority: project.priority,
        status: project.status,
        actuals_or_forecast: [],
        budget: [],
        actuals: [],
        forecast: [],
        all_data: project,
      };
      project?.items?.forEach((item) => {
        item.budget.forEach((budget) => {
          if (
            Array.isArray(budget.monthly_budget) ||
            Array.isArray(budget.monthly_budget) ||
            Array.isArray(budget.monthly_budget)
          ) {
            budget.monthly_budget = { ...defaultMonthlyData };
            budget.monthly_forecast = { ...defaultMonthlyData };
            budget.monthly_actuals = { ...defaultMonthlyData };
          }
        });
      });

      this.buildCashFlowViewLineItemData(project, false); // will modify lineItems with filtered line item values
      this.projectYearOptions.forEach((year) => {
        const actFcstData = {
          values: new Array(12).fill(0),
          year,
        };
        const budgetData = {
          values: new Array(12).fill(0),
          year,
        };
        const actualsData = {
          values: new Array(12).fill(0),
          year,
        };
        const forecastData = {
          values: new Array(12).fill(0),
          year,
        };

        const filterFunction = (bud) => bud.year === year;
        const actFcstFilteredByYear = this.lineItems.map((item) =>
          item.actuals_or_forecast.filter(filterFunction),
        );
        const budgetFilteredByYear = this.lineItems.map((item) =>
          item.budget.filter(filterFunction),
        );
        const actualsFilteredByYear = this.lineItems.map((item) =>
          item.actuals.filter(filterFunction),
        );
        const forecastFilteredByYear = this.lineItems.map((item) =>
          item.forecast.filter(filterFunction),
        );

        // '?' checks need to be there because empty arrays need to be handled
        actFcstFilteredByYear.forEach((filteredData) => {
          const values = filteredData?.[0]?.values;
          actFcstData.values =
            values?.map((num, idx) => num + actFcstData.values[idx]) ?? actFcstData.values;
        });
        budgetFilteredByYear.forEach((filteredData) => {
          const values = filteredData?.[0]?.values;
          budgetData.values =
            values?.map((num, idx) => num + budgetData.values[idx]) ?? budgetData.values;
        });

        actualsFilteredByYear.forEach((filteredData) => {
          const values = filteredData?.[0]?.values;
          actualsData.values =
            values?.map((num, idx) => num + actualsData.values[idx]) ?? actualsData.values;
        });

        forecastFilteredByYear.forEach((filteredData) => {
          const values = filteredData?.[0]?.values;
          forecastData.values =
            values?.map((num, idx) => num + forecastData.values[idx]) ?? forecastData.values;
        });
        projectData.actuals_or_forecast.push(actFcstData);
        projectData.budget.push(budgetData);
        projectData.actuals.push(actualsData);
        projectData.forecast.push(forecastData);
      });
      projectsData.push(projectData);
    });
    this.projects = projectsData.toSorted(this.sortFunction);

    this.allYearOptions = new Set<number>();
    this.projects.forEach((p) => {
      p.actuals_or_forecast.forEach((data) => this.allYearOptions.add(data.year));
    });
  }

  /**
   * @deprecated - use backend data instead
   */
  buildCashFlowViewLineItemData(data: any, isViewProject = true) {
    const yearOptions = new Set();
    this.allYearOptions = new Set();
    this.lineItems = [];
    const lineItems: {
      id: number;
      name: string;
      budget: number[];
      actuals_or_forecast: number[];
      actuals: number[];
      forecast: number[];
    }[] = [];
    const budgetData = isViewProject ? data : data?.items;
    budgetData?.forEach((item) => {
      const yearlyLineItemData = {
        id: item.id,
        name: item.name,
        budget: [],
        actuals_or_forecast: [],
        actuals: [],
        forecast: [],
      };

      item.budget.forEach((budget) => {
        const actualsOrForecastArray = {
          values: [],
          year: budget.year,
        };
        const budgetArray = {
          values: [],
          year: budget.year,
        };
        const actualsArray = {
          values: [],
          year: budget.year,
        };
        const forecastArray = {
          values: [],
          year: budget.year,
        };
        yearOptions.add(budget.year);
        this.allYearOptions.add(budget.year);

        /*
         Month Columns: Actual or Committed dollar amounts allocated for each month, depending on the following situations:
          1.  if the month is a past month, the table will show actual costs (Paid Invoice + Misc Cost dollars)
          2. if the month is a current month and the Actuals < Committed dollars, the table will show Committed dollar amounts
          3. if the month is a current month and the Actuals > Committed dollars, a rebalance of forecast has been triggered,
          and still the Committed dollar amounts are shown
          4. if the month is a future month, the table will show Committed dollars
         */

        for (let month = 1; month <= 12; month++) {
          const isForecastMonth = this.fiscalService.isForecastMonth(month - 1, budget.year);
          const isCurrentMonth = this.fiscalService.isCurrentMonth(month, budget.year);
          const forecastValue = budget.monthly_forecast[month];
          const actualsValue = budget.monthly_actuals[month];

          if (isCurrentMonth) {
            actualsOrForecastArray.values.push(forecastValue);
          } else if (isForecastMonth) {
            actualsOrForecastArray.values.push(forecastValue);
          } else {
            actualsOrForecastArray.values.push(actualsValue);
          }

          budgetArray.values.push(budget.monthly_budget[month]);
          actualsArray.values.push(budget.monthly_actuals[month]);
          forecastArray.values.push(budget.monthly_forecast[month]);
        }
        yearlyLineItemData.actuals_or_forecast.push(actualsOrForecastArray);
        yearlyLineItemData.budget.push(budgetArray);
        yearlyLineItemData.actuals.push(actualsArray);
        yearlyLineItemData.forecast.push(forecastArray);
      });
      lineItems.push(yearlyLineItemData);
      this.projectYearOptions = Array.from(yearOptions);
    });

    this.lineItems = lineItems.toSorted(this.sortFunction);
  }

  /**
   * @deprecated - use backend data instead
   */
  calculateTotals(filteredLineItems) {
    if (!filteredLineItems) {
      console.warn('No line items for cashflow service calculateTotals, this may break things.');
      return;
    }
    // last array item contains FY totals
    const actOrFcstTotals = Array(12).fill(0);
    const budgetTotals = Array(12).fill(0);
    const actualsTotals = Array(12).fill(0);
    const forecastTotals = Array(12).fill(0);
    filteredLineItems.forEach((filteredData) => {
      filteredData.actuals_or_forecast?.values?.forEach((value, index) => {
        actOrFcstTotals[index] += value;
      });
      filteredData.budget?.values?.forEach((value, index) => {
        budgetTotals[index] += value;
      });
      filteredData.actuals?.values?.forEach((value, index) => {
        actualsTotals[index] += value;
      });
      filteredData.forecast?.values?.forEach((value, index) => {
        forecastTotals[index] += value;
      });
    });

    return { actOrFcstTotals, budgetTotals, actualsTotals, forecastTotals };
  }
}
