import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cashflowFeatureKey, CashflowState } from './cashflow.reducer';

const getCashflowState = createFeatureSelector<CashflowState>(cashflowFeatureKey);
const isLoading = createSelector(getCashflowState, (state) => state.isLoading);

const getTableData = createSelector(getCashflowState, (state) => state?.cashflow?.data);
const getNotEmptyData = createSelector(getTableData, (data) =>
  data?.filter((d) => !!d?.table_data),
);
const getTotals = createSelector(getCashflowState, (state) => state?.cashflow?.totals);
const firstForecastMonth = createSelector(
  getCashflowState,
  (state) => state?.cashflow?.first_forecast_month,
);

const sortNameBy = createSelector(getCashflowState, (state) => state.sortNameBy);

export const cashflowSelectors = {
  getCashflowState,
  isLoading,
  getTableData,
  getNotEmptyData,
  getTotals,
  firstForecastMonth,
  sortNameBy,
};
