import { specialCharReplaceRegexp } from './spend.constants';

export const ALL_ID = -1;

export type CashflowFilters = {
  view: 'projects' | 'budget_lines';
  project_ids?: number[];
  property_ids?: number[];
  project_status_ids?: number[];
  year: number;
  searchText?: string;
  show_zero_dollar_lines?: 0 | 1;
};

export type Cashflow = {
  data: CashflowData[];
  first_forecast_month: number; // 1-based index
  totals: CashflowTotals;
};

export type CashflowData = {
  project_id: number;
  line_id: number | null; // null if view is not 'budget_lines'
  row_number?: number;
  name: string;
  property_id: number;
  property: {
    id: number;
    name: string;
    formatted_address: string;
  };
  table_data: CashflowTableData;
};

export type CashflowTableData = {
  monthly_budget: number[];
  monthly_actuals_or_forecast: number[];
  year: number;
  year_total_actuals_or_forecast: number;
};

export type CashflowTotals = {
  budget: number;
  actuals: number;
  forecast: number;
  current_month_actuals: number;
  actuals_or_forecast: number;
  monthly_budget: number[];
  monthly_actuals_or_forecast: number[];
  year: number;
};

export type NameSorting = 'asc' | 'desc' | 'row_number';

export const getCashflowDataSorted = (cashflowData: CashflowData[], sortNameBy: NameSorting) =>
  cashflowData.toSorted((a: CashflowData, b: CashflowData) => {
    if (!a.name || !b.name) {
      return a.row_number - b.row_number;
    }
    const aField = a.name.replace(specialCharReplaceRegexp, '');
    const bField = b.name.replace(specialCharReplaceRegexp, '');

    if (sortNameBy === 'asc') {
      return aField.localeCompare(bField);
    } else if (sortNameBy === 'desc') {
      return bField.localeCompare(aField);
    }

    return a.row_number - b.row_number;
  });
